@font-face {
font-family: 'fonts';
src: url(/_next/static/media/173b94bf67683b13-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'fonts';
src: url(/_next/static/media/afb7b22a7bdf86ea-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'fonts';
src: url(/_next/static/media/6269db6f7b4be56b-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'fonts Fallback';src: local("Arial");ascent-override: 93.21%;descent-override: 44.38%;line-gap-override: 0.00%;size-adjust: 102.41%
}.__className_b0a90a {font-family: 'fonts', 'fonts Fallback'
}

